<template>
  <div>
    <div class="printable-page">
      ใบปิดยอด <br />

      <div v-for="(x, index) in payments" :key="index">
        {{ x }} <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      payments: [],
    };
  },
  created() {
    this.load_data();
  },
  methods: {
    async load_data() {
      await this.$http({
        method: "POST",
        url: `payment/day-close`,
      })
        .then((x) => {
          console.log("payment", x.data.data);
          this.payments = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>

<style>
@page {
  size: A4;
}
.printable-page {
  background-color: white !important;
  padding: 1cm;
}
/* @media print {
  body {
    background-color: white !important;
    font-family: "Sarabun", sans-serif !important;
  }
} */

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Sarabun", sans-serif !important;
  color: black !important;
  background-color: white !important;
}
</style>
